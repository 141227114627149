.ui-dialog-default-panel {
  direction: rtl;
  .mat-mdc-dialog-container {
    padding: 0 !important;
    border-radius: 0 !important;
    position: relative;
  }
  .mat-mdc-dialog-surface {
    box-shadow: none !important;
    border-radius: 1.75rem !important;
    background: transparent !important;
    & > * {
      @apply shadow-5 #{!important};
      @apply bg-gray-50;
      display: flex;
      flex-direction: column;
      padding: 0;
    }
  }
}

.ui-backdrop-blur {
  background: rgba(69, 82, 105, 0.7) !important;
  backdrop-filter: blur(4px) !important;
}

.ui-snackbar-default {
  padding: 0 !important;
  font-family: inherit;
  font-weight: inherit;
  .mdc-snackbar__surface {
    background: transparent !important;
    box-shadow: none !important;
    font-family: inherit;
    font-weight: inherit;
    border-radius: 0 !important;
    padding: 0 !important;
    pointer-events: none !important;
  }
  .mat-mdc-snack-bar-label {
    font-family: inherit !important;
    font-weight: inherit !important;
    color: inherit !important;
    font-size: 0.875rem !important;
  }
}

mat-label,
label,
p,
span,
input,
textarea,
div {
  letter-spacing: 0 !important;
}

mat-checkbox {
  .mdc-checkbox__checkmark {
    color: white !important;
  }
  .mdc-checkbox__background {
    border-width: 1px !important;
    @apply border-outline #{!important};
  }
  .mdc-label {
    @apply text-outline;
    font-weight: 500;
  }
}

mat-tab-group {
  .mat-mdc-tab.mdc-tab,
  .mat-mdc-tab-labels,
  .mat-mdc-tab-label-container,
  mat-tab-header {
    height: 100% !important;
  }
  .mat-mdc-tab-label-container {
    //overflow-x: auto;
  }
  .mdc-tab__text-label {
    @apply text-sm;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    font-weight: 500;
  }
  .mdc-tab--active {
    @apply bg-tertiary-50;
    .mdc-tab__text-label {
      @apply text-primary-600 #{!important};
    }
  }
  .mdc-tab {
    min-width: initial !important;
  }
}

$width: 15rem;
mat-calendar {
  width: $width;
  height: 20rem;
  & * > .mat-calendar-header {
    height: 20%;
  }
  .mat-calendar-content {
    height: 80%;
    & * > .mat-calendar-table-header-divider::after {
      display: none;
    }
    mat-year-view,
    mat-multi-year-view,
    mat-month-view {
      display: flex;
      height: 100%;
    }
    mat-year-view {
      & * > .mat-calendar-body-cell-content {
        font-size: 10px;
        font-weight: bold;
      }
    }
    & * > .mat-calendar-body-cell {
      height: 2.5rem;
    }
    & * > .mat-calendar-body-in-range::before {
      @apply bg-secondary-container;
    }
  }
}

.cdk-overlay-pane.mat-datepicker-popup .mat-datepicker-content {
  border-radius: 1rem !important;
  .mat-calendar {
    height: max-content !important;
  }
  .mat-datepicker-content-container.mat-datepicker-content-container-with-actions {
    @apply bg-surface-light-surface;
  }
}
