@tailwind base;
@tailwind components;
@tailwind utilities;
@import "theme/fonts";
@import "theme/colors";
@import "theme/material";
@import "theme/design-system";

html,
body {
  height: 100%;
  font-family: "Estedad", "sans-serif";
}
body {
  margin: 0;
}

input, textarea {
  unicode-bidi: plaintext;
}

::-webkit-scrollbar {
  width: 11px;
}
::-webkit-scrollbar-thumb {
  @apply bg-gray-200 hover:bg-gray-300;
  border-radius: 5px;
  border: 3px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
}

