:root {
  --primary-50: #b2fff6; // 95
  --primary-100: #71f7eb; // 90
  --primary-200: #50dbcf; // 80
  --primary-300: #26bfb4; // 70
  --primary-400: #00a298; // 60
  --primary-500: #00867e; // 50
  --primary-600: #006a64; // 40
  --primary-700: #00504b; // 30
  --primary-800: #003733; // 20
  --primary-900: #00201e; // 10
  --primary-default: var(--primary-600);
  --primary-container: var(--primary-100);
  --primary-on-container: var(--primary-900);
  --primary-inverse: var(--primary-200);

  --secondary-50: #FFEDE7; // 95
  --secondary-100: #FFDBCC; // 90
  --secondary-200: #FFB595; // 80
  --secondary-300: #F8905F; // 70
  --secondary-400: #D87648; // 60
  --secondary-500: #B85E31; // 50
  --secondary-600: #99461B; // 40
  --secondary-700: #7A3004; // 30
  --secondary-800: #571E00; // 20
  --secondary-900: #351000; // 10
  --secondary-default: var(--secondary-600);
  --secondary-container: var(--secondary-100);
  --secondary-on-container: var(--secondary-900);

  --tertiary-50: #E8F1FF; // 95
  --tertiary-100: #CFE5FF; // 90
  --tertiary-200: #AFC9E7; // 80
  --tertiary-300: #94AECA; // 70
  --tertiary-400: #7A93AF; // 60
  --tertiary-500: #607994; // 50
  --tertiary-600: #47617A; // 40
  --tertiary-700: #2F4962; // 30
  --tertiary-800: #17324A; // 20
  --tertiary-900: #001D33; // 10
  --tertiary-default: var(--tertiary-600);
  --tertiary-container: var(--tertiary-100);
  --tertiary-on-container: var(--tertiary-900);

  --error-50: #FFDAD6; // 95
  --error-100: #FFDAD6; // 90
  --error-200: #FFB4AB; // 80
  --error-300: #FF897D; // 70
  --error-400: #FF5449; // 60
  --error-500: #DE3730; // 50
  --error-600: #BA1A1A; // 40
  --error-700: #93000A; // 30
  --error-800: #690005; // 20
  --error-900: #410002; // 10
  --error-default: var(--error-600);
  --error-container: var(--error-100);
  --error-on-container: var(--error-900);
}
