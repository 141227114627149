@font-face {
  font-family: "Estedad";
  src: url("../assets/fonts/Estedad/Estedad-FD-Thin.ttf") format("truetype"),
    url("../assets/fonts/Estedad/Estedad-FD-Thin.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Estedad";
  src: url("../assets/fonts/Estedad/Estedad-FD-ExtraLight.ttf")
      format("truetype"),
    url("../assets/fonts/Estedad/Estedad-FD-ExtraLight.woff2") format("woff2");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Estedad";
  src: url("../assets/fonts/Estedad/Estedad-FD-Light.ttf") format("truetype"),
    url("../assets/fonts/Estedad/Estedad-FD-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Estedad";
  src: url("../assets/fonts/Estedad/Estedad-FD-Regular.ttf") format("truetype"),
    url("../assets/fonts/Estedad/Estedad-FD-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Estedad";
  src: url("../assets/fonts/Estedad/Estedad-FD-Medium.ttf") format("truetype"),
    url("../assets/fonts/Estedad/Estedad-FD-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Estedad";
  src: url("../assets/fonts/Estedad/Estedad-FD-SemiBold.ttf") format("truetype"),
    url("../assets/fonts/Estedad/Estedad-FD-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Estedad";
  src: url("../assets/fonts/Estedad/Estedad-FD-Bold.ttf") format("truetype"),
    url("../assets/fonts/Estedad/Estedad-FD-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Estedad";
  src: url("../assets/fonts/Estedad/Estedad-FD-ExtraBold.ttf") format("truetype"),
    url("../assets/fonts/Estedad/Estedad-FD-ExtraBold.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Estedad";
  src: url("../assets/fonts/Estedad/Estedad-FD-Black.ttf") format("truetype"),
    url("../assets/fonts/Estedad/Estedad-FD-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
}


@font-face {
  font-family: "Traffic";
  src: url("../assets/fonts/Traffic/TrafficBd.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
